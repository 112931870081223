import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";

function OrderTableRow(props) {
  const { order_id, customer_id, order_date, order_status, total_amount, payment_status, shipping_id } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
       <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="sm" color={textColor} fontWeight="bold">
            {order_id}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="sm" color={textColor} fontWeight="bold" align='center'>
            {customer_id}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="sm" color={textColor} fontWeight="bold" align='center'>
            {order_date}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="sm" color={textColor} fontWeight="bold" align='center'>
            {order_status}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="sm" color={textColor} fontWeight="bold" align='center'>
            {total_amount}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="sm" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {payment_status}
        </Text>
      </Td>
    </Tr>
  );
}

export default OrderTableRow;
