import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";

function HistoryTableRow(props) {
  const { history_id, order_id, action_date, action_type, action_by } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const titleColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
       <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {history_id}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" align='center'>
            {order_id}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" align='center'>
            {action_date}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {action_type}
        </Text>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {action_by}
        </Text>
      </Td>
    </Tr>
  );
}

export default HistoryTableRow;
