// Chakra imports
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
import OrderTableRow from "components/Tables/OrderTableRow";
import { GetOrderByUserId } from "lib/service/api";
  import React, { useEffect, useState } from "react";
import { orderData } from "variables/general";
  
  function Order() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [orders, setOrders] = useState([])

    useEffect(() => {
        handleGetOrder();
    }, [])

    async function handleGetOrder() {
        const rowOrders = await GetOrderByUserId();
        setOrders(rowOrders)
    }

    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Order Table
            </Text>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400" >
                <Th borderColor={borderColor} color="gray.400">Order ID</Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Customer ID</Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Order Date</Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Order Status</Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Total Amount</Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Payment Status</Th> 
                </Tr>
              </Thead>
              <Tbody>
                {orders.map((row, index, arr) => {
                  return (
                    <OrderTableRow
                      order_id={row.order_id}
                      customer_id={row.customer_id}
                      order_date={row.order_date}
                      order_status={row.order_status}
                      total_amount={row.total_amount}
                      payment_status={row.payment_status}
                      shipping_id={row.shipping_id}
                      key={index}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default Order;
  