// Chakra imports
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
import ShippingTableRow from "components/Tables/ShippingTableRow";
import { GetProfile } from "lib/service/api";
import { GetShippingByUserId } from "lib/service/api";
  import React, { useEffect, useState } from "react"; 
  
  function Shipping() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const [shipping, setShipping] = useState([])

  useEffect(() => {
      handleGetShipping();
  }, [])

  async function handleGetShipping() {
      const resShipping = await GetShippingByUserId(GetProfile());
      setShipping(resShipping)
  }

    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Shipping Table
            </Text>
          </CardHeader>
          <CardBody>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400" >
                <Th borderColor={borderColor} color="gray.400">Shipping ID</Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Order ID</Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Shipping Address</Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Shipping Method</Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Shipping Status</Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Shipping Date </Th>
                  <Th borderColor={borderColor} color="gray.400" textAlign="center">Tracking Number</Th>
                </Tr>
              </Thead>
              <Tbody>
                {shipping.map((row, index, arr) => {
                  return (
                    <ShippingTableRow
                      shipping_id={row.shipping_id}
                      order_id={row.order_id}
                      shipping_address={row.shipping_address}
                      shipping_method={row.shipping_method}
                      shipping_status={row.shipping_status}
                      shipping_date={row.shipping_date}
                      tracking_number={row.tracking_number}
                      key={index}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default Shipping;
  