import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Icon,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets 
import { FaApple, FaFacebook, FaGoogle, FaLine, FaWhatsapp } from "react-icons/fa";
import { handleSignIn } from "lib/service/api";
import { auth } from "lib/firebase/firebase";
import { signInWithFacebook } from "lib/firebase/firebase";
import { signInWithGoogle } from "lib/firebase/firebase";
import { API_URL } from "lib/service/api";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const colorIcons = useColorModeValue("gray.700", "white");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);

  const handleGoogleLogin = async () => {
    const token = await signInWithGoogle();
    setUser(null);
    if (token) {
      console.log("Google Token:", token);
      // Send token to your backend API for verification
      const response = await fetch(`${API_URL}/api/auth/google-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      console.log("Backend Response:", data);
      setUser(data.user)
      toast.success("Login successful");
    }
  };

  const handleFacebookLogin = async () => {
    const token = await signInWithFacebook();
    setUser(null);
    if (token) {
      console.log("Facebook Token:", token);
      // Send token to your backend API for verification
      const response = await fetch(`${API_URL}/api/auth/facebook-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      console.log("Backend Response:", data);
      setUser(data.user)
      toast.success("Login successful");
    }
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setUser(null);
    try {
      const token = await signInWithEmailAndPassword(auth, email, password);
      console.log("Email login:", token);
      const response = await fetch(`${API_URL}/api/auth/email-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: token._tokenResponse.idToken }),
      });
      const data = await response.json();
      console.log("Backend Response:", data);
      setUser(data.user)
      toast.success("Login successful");

    } catch (error) {
      toast.error(error.message || "Login failed");
      console.error("Error logging in:", error);
    }
  };

  const handleEmailRegister = async (e) => {
    e.preventDefault();
    setUser(null);
    try {
      const token = await createUserWithEmailAndPassword(auth, email, password);
      const response = await fetch(`${API_URL}/api/auth/email-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: token._tokenResponse.idToken }),
      });
      const data = await response.json();
      console.log("Backend Response:", data);
      setUser(data.user)
      toast.success("Registration successful");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        toast.error("User already exists");
      } else {
        toast.error(error.message || "Registration failed");
      }
      console.error("Error registering:", error);
    }
  };

  function handleClick() {
    console.log('test');
  }

  function handleClickLogin() {
    handleSignIn()

    window.open('/', '_self')
  }

  return (
    <Flex position='relative' mb='40px'>
      <Flex
        minH={{ md: "1000px" }}
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ md: "0px" }}>
        <Flex
          w='100%'
          h='100%'
          alignItems='center'
          justifyContent='center'
          mb='60px'
          mt={{ base: "50px", md: "20px" }}>
          <Flex
            zIndex='2'
            direction='column'
            w='445px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: "100px" }}
            m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}>
            <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              Register With
            </Text>
            <HStack spacing='15px' justify='center' mb='22px'>
              <Button className="!bg-transparent !w-[75px] !h-[75px] border" onClick={handleFacebookLogin}>
                <Icon as={FaFacebook} color={colorIcons} w='30px' h='30px' />
              </Button>
              <Button className="!bg-transparent !w-[75px] !h-[75px] border" onClick={handleGoogleLogin}>
                <Icon
                  as={FaGoogle}
                  color={colorIcons}
                  w='30px'
                  h='30px'
                  _hover={{ filter: "brightness(120%)" }}
                />
              </Button>
              <Button className="!bg-transparent !w-[75px] !h-[75px] border" onClick={handleClickLogin}>
                <Icon
                  as={FaLine}
                  color={colorIcons}
                  w='30px'
                  h='30px'
                  _hover={{ filter: "brightness(120%)" }}
                />
              </Button>
              <Button className="!bg-transparent !w-[75px] !h-[75px] border" onClick={handleClickLogin}>
                <Icon
                  as={FaWhatsapp}
                  color={colorIcons}
                  w='30px'
                  h='30px'
                  _hover={{ filter: "brightness(120%)" }}
                />
              </Button>
            </HStack>
            <Text
              fontSize='lg'
              color='gray.400'
              fontWeight='bold'
              textAlign='center'
              mb='22px'>
              or
            </Text>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Name
              </FormLabel>
              <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='text'
                placeholder='Your full name'
                mb='24px'
                size='lg'
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Password
              </FormLabel>
              <Input
                variant='auth'
                fontSize='sm'
                ms='4px'
                type='password'
                placeholder='Your password'
                mb='24px'
                size='lg'
              />
              <FormControl display='flex' alignItems='center' mb='24px'>
                <Switch id='remember-login' colorScheme='blue' me='10px' />
                <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal'>
                  Remember me
                </FormLabel>
              </FormControl>
              <Button
                onClick={handleClickLogin}
                fontSize='10px'
                variant='dark'
                fontWeight='bold'
                w='100%'
                h='45'
                mb='24px'>
                SIGN IN
              </Button>
            </FormControl>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                Already have an account?
                <a
                  className="font-semibold ml-[4px]"
                  color={titleColor}
                  as='span'
                  ms='5px'
                  href='/sign-up'
                  fontWeight='bold'>
                  Sign Up
                </a>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          overflowX='hidden'
          h='100%'
          w='100%'
          left='0px'
          position='absolute'>
          <Box
            w='100%'
            h='100%'
            bgSize='cover'
            bg='blue.500'
            opacity='0.8'></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
