// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from "firebase/auth";

// Firebase configuration (replace these values with your own Firebase config)
const firebaseConfig = {
    apiKey: "AIzaSyDq9szBjKb03IcIz7b9UKYqyqCPpxRLFRs",
    authDomain: "img-q-c30fd.firebaseapp.com",
    projectId: "img-q-c30fd",
    storageBucket: "img-q-c30fd.appspot.com",
    messagingSenderId: "874833647970",
    appId: "1:874833647970:web:b0a83f7d0d6266326ef43b"
};


// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get authentication providers
export const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
// Google sign-in function
export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        const token = await result.user.getIdToken(); // Get the OAuth token
        return token;
    } catch (error) {
        console.error("Google sign-in error", error);
    }
};

// Facebook sign-in function
export const signInWithFacebook = async () => {
    try {
        const result = await signInWithPopup(auth, facebookProvider);
        const token = await result.user.getIdToken(); // Get the OAuth token
        return token;
    } catch (error) {
        console.error("Facebook sign-in error", error);
    }
};
