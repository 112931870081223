import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";

function PaymentTableRow(props) {
  const { payment_id, order_id, payment_method, payment_date, payment_status, amount_paid, transaction_id } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
       <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {payment_id}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" align='center'>
            {order_id}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" align='center'>
            {payment_method}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {payment_date}
        </Text>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {payment_status}
        </Text>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {amount_paid}
        </Text>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {transaction_id}
        </Text>
      </Td>
    </Tr>
  );
}

export default PaymentTableRow;
