import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './index.css'

import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js"; 
import UserLayout from "layouts/User.js"; 
import { ChakraProvider } from "@chakra-ui/react";
// Custom Chakra theme
import theme from "theme/theme.js";

ReactDOM.render(
  <ChakraProvider theme={theme} resetCss={false} position="relative">
    <BrowserRouter>
      <Switch>
        <Route path={`/auth`} component={AuthLayout} />
        <Route path={`/user`} component={AdminLayout} />
        <Route path={`/sign-in`} component={SignIn} />
        <Route path={`/sign-up`} component={SignUp} />
        <Route path={`/`} component={UserLayout} />
      </Switch>
    </BrowserRouter>
  </ChakraProvider>,
  document.getElementById("root")
);
