// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  LightMode,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { signInWithFacebook } from "lib/firebase/firebase";
import { signInWithGoogle } from "lib/firebase/firebase";
import { handleSignIn } from "lib/service/api";
import { API_URL } from "lib/service/api";
import React, { useState } from "react";
import { FaApple, FaFacebook, FaGoogle, FaLine, FaWhatsapp } from "react-icons/fa";

function SignUp() {
  const bgForm = useColorModeValue("white", "navy.800");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const textColor = useColorModeValue("gray.700", "white");
  const colorIcons = useColorModeValue("gray.700", "white");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);

  const handleGoogleLogin = async () => {
    const token = await signInWithGoogle();
    setUser(null);
    if (token) {
      console.log("Google Token:", token);
      // Send token to your backend API for verification
      const response = await fetch(`${API_URL}/api/auth/google-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      console.log("Backend Response:", data);
      setUser(data.user)
      toast.success("Login successful");
    }
  };

  const handleFacebookLogin = async () => {
    const token = await signInWithFacebook();
    setUser(null);
    if (token) {
      console.log("Facebook Token:", token);
      // Send token to your backend API for verification
      const response = await fetch(`${API_URL}/api/auth/facebook-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      console.log("Backend Response:", data);
      setUser(data.user)
      toast.success("Login successful");
    }
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setUser(null);
    try {
      const token = await signInWithEmailAndPassword(auth, email, password);
      console.log("Email login:", token);
      const response = await fetch(`${API_URL}/api/auth/email-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: token._tokenResponse.idToken }),
      });
      const data = await response.json();
      console.log("Backend Response:", data);
      setUser(data.user)
      toast.success("Login successful");

    } catch (error) {
      toast.error(error.message || "Login failed");
      console.error("Error logging in:", error);
    }
  };

  const handleEmailRegister = async (e) => {
    e.preventDefault();
    setUser(null);
    try {
      const token = await createUserWithEmailAndPassword(auth, email, password);
      const response = await fetch(`${API_URL}/api/auth/email-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: token._tokenResponse.idToken }),
      });
      const data = await response.json();
      console.log("Backend Response:", data);
      setUser(data.user)
      toast.success("Registration successful");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        toast.error("User already exists");
      } else {
        toast.error(error.message || "Registration failed");
      }
      console.error("Error registering:", error);
    }
  }; 

  function handleClickLogin() {
    handleEmailRegister()

    handleSignIn()

    window.open('/', '_self')
  }

  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'>
      <Box
        position='absolute'
        minH={{ base: "70vh", md: "50vh" }}
        maxH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        maxW={{ md: "calc(100vw - 50px)" }}
        left='0'
        right='0'
        bgRepeat='no-repeat'
        overflow='hidden'
        zIndex='-1'
        top='0'
        bgSize='cover'
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
        borderRadius={{ base: "0px", md: "20px" }}>
        <Box w='100vw' h='100vh' bg='blue.500' opacity='0.8'></Box>
      </Box>
      <Flex
        direction='column'
        textAlign='center'
        justifyContent='center'
        align='center'
        mt='125px'
        mb='30px'>
        <Text fontSize='4xl' color='white' fontWeight='bold'>
          Welcome!
        </Text>
        <Text
          fontSize='md'
          color='white'
          fontWeight='normal'
          mt='10px'
          mb='26px'
          w={{ base: "90%", sm: "60%", lg: "40%", xl: "333px" }}>
          Use these awesome forms to login or create new account in your project
          for free.
        </Text>
      </Flex>
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='445px'
          background='transparent'
          borderRadius='15px'
          p='40px'
          mx={{ base: "100px" }}
          bg={bgForm}
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}>
          <Text
            fontSize='xl'
            color={textColor}
            fontWeight='bold'
            textAlign='center'
            mb='22px'>
            Register With
          </Text>
          <HStack spacing='15px' justify='center' mb='22px'>
            <Button className="!bg-transparent !w-[75px] !h-[75px] border" onClick={handleFacebookLogin}>
              <Icon as={FaFacebook} color={colorIcons} w='30px' h='30px' />
            </Button>
            <Button className="!bg-transparent !w-[75px] !h-[75px] border" onClick={handleGoogleLogin}>
              <Icon
                as={FaGoogle}
                color={colorIcons}
                w='30px'
                h='30px'
                _hover={{ filter: "brightness(120%)" }}
              />
            </Button>
            <Button className="!bg-transparent !w-[75px] !h-[75px] border" onClick={handleClickLogin}>
              <Icon
                as={FaLine}
                color={colorIcons}
                w='30px'
                h='30px'
                _hover={{ filter: "brightness(120%)" }}
              />
            </Button>
            <Button className="!bg-transparent !w-[75px] !h-[75px] border" onClick={handleClickLogin}>
              <Icon
                as={FaWhatsapp}
                color={colorIcons}
                w='30px'
                h='30px'
                _hover={{ filter: "brightness(120%)" }}
              />
            </Button>
          </HStack>
          <Text
            fontSize='lg'
            color='gray.400'
            fontWeight='bold'
            textAlign='center'
            mb='22px'>
            or
          </Text>
          <FormControl>
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Name
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              ms='4px'
              type='text'
              placeholder='Your full name'
              mb='24px'
              size='lg'
            />
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Email
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              ms='4px'
              type='email'
              placeholder='Your email address'
              mb='24px'
              size='lg'
            />
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
              Password
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              ms='4px'
              type='password'
              placeholder='Your password'
              mb='24px'
              size='lg'
            />
            <FormControl display='flex' alignItems='center' mb='24px'>
              <Switch id='remember-login' colorScheme='blue' me='10px' />
              <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal'>
                Remember me
              </FormLabel>
            </FormControl>
            <Button
              onClick={handleClickLogin}
              fontSize='10px'
              variant='dark'
              fontWeight='bold'
              w='100%'
              h='45'
              mb='24px'>
              SIGN UP
            </Button>
          </FormControl>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            maxW='100%'
            mt='0px'>
            <Text color={textColor} fontWeight='medium'>
              Already have an account?
              <a
                className="font-semibold ml-[4px]"
                color={titleColor}
                as='span'
                ms='5px'
                href='/sign-in'
                fontWeight='bold'>
                Sign In
              </a>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
