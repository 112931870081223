// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import PaymentTableRow from "components/Tables/PaymentTableRow";
import { GetPaymentsById } from "lib/service/api";
import React, { useEffect, useState } from "react"; 

function Payment() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [payments, setPayments] = useState([])

  useEffect(() => {
      handleGetPayment();
  }, [])

  async function handleGetPayment() {
      const rowPayments = await GetPaymentsById();
      setPayments(rowPayments)
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
          Payment Table
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400" >
              <Th borderColor={borderColor} color="gray.400">Payment ID</Th>
                <Th borderColor={borderColor} color="gray.400" textAlign="center">Order ID</Th>
                <Th borderColor={borderColor} color="gray.400" textAlign="center">Payment Method</Th>
                <Th borderColor={borderColor} color="gray.400" textAlign="center">Payment Date</Th>
                <Th borderColor={borderColor} color="gray.400" textAlign="center">Payment Status</Th>
                <Th borderColor={borderColor} color="gray.400" textAlign="center">Amount Paid</Th>
                <Th borderColor={borderColor} color="gray.400" textAlign="center">Transaction ID</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payments.map((row, index, arr) => {
                return (
                  <PaymentTableRow
                    payment_id={row.payment_id}
                    order_id={row.order_id}
                    payment_method={row.payment_method}
                    payment_date={row.payment_date}
                    payment_status={row.payment_status}
                    amount_paid={row.amount_paid}
                    transaction_id={row.transaction_id}
                    key={index}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Payment;
