import Cookies from 'universal-cookie';
import Ip1 from '../../assets/products/ip-black.webp'
import Ip2 from '../../assets/products/ip-pink.webp'
import Ip3 from '../../assets/products/ip-purple.jpg'
import Ip4 from '../../assets/products/ip-white.webp'

const cookies = new Cookies();

export const API_URL = 'https://react-auth-server-tau.vercel.app';

export function handleSignIn() {
    const mockUpToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwicGxhdGZvcm0iOiJnb29nbGUiLCJyb2xlIjoidXNlciIsImlhdCI6MTUxNjIzOTAyMn0.n_r8z6AIfS7qQ4H6IUb2LOfiICReE8wG_TFQ2DUUDMc"

    cookies.set('token', mockUpToken, { path: '/' });

    return {
        accessToken: mockUpToken
    }
}


export function GetProfile() {
    const token = cookies.get('token');

    return token
}

export function GetProducts() {
    return [
        {
            "request_id": "4b168c5a-fdab-11eb-9a03-0242ac130003",
            "product": {
                "brand": "iPhone",
                "image": Ip1,
                "link": "https://s.lazada.co.th/s.qmftI",
                "model": "iPhone 16 Clear Case with MagSafe",
                "name": "iPhone 16 Clear Case with MagSafe",
                "price": "฿1,990.00"
            }
        },
        {
            "request_id": "4b168c5a-fdab-11eb-9a03-0242ac130003",
            "product": {
                "brand": "iPhone",
                "image": Ip2,
                "link": "https://s.lazada.co.th/s.qmftI",
                "model": "iPhone 16 Clear Case with MagSafe",
                "name": "iPhone 16 Clear Case with MagSafe",
                "price": "฿1,990.00"
            }
        },
        {
            "request_id": "4b168c5a-fdab-11eb-9a03-0242ac130003",
            "product": {
                "brand": "iPhone",
                "image": Ip3,
                "link": "https://s.lazada.co.th/s.qmftI",
                "model": "iPhone 16 Clear Case with MagSafe",
                "name": "iPhone 16 Clear Case with MagSafe",
                "price": "฿1,990.00"
            }
        },
        {
            "request_id": "4b168c5a-fdab-11eb-9a03-0242ac130003",
            "product": {
                "brand": "iPhone",
                "image": Ip4,
                "link": "https://s.lazada.co.th/s.qmftI",
                "model": "iPhone 16 Clear Case with MagSafe",
                "name": "iPhone 16 Clear Case with MagSafe",
                "price": "฿1,990.00"
            }
        }
    ]
}

export function GetOrderByUserId(token) {
    return [
        {
            order_id: "ORD12345",
            customer_id: "CUS001",
            order_date: "2024-09-25",
            order_status: "Pending",
            total_amount: 100.5,
            payment_status: "Pending",
            shipping_id: "SHIP123",
            order_notes: "Please gift wrap the item",
        },
        {
            order_id: "ORD12346",
            customer_id: "CUS001",
            order_date: "2024-09-24",
            order_status: "Pending",
            total_amount: 200.75,
            payment_status: "Success",
            shipping_id: "SHIP124",
            order_notes: "Deliver after 6 PM",
        },
        {
            order_id: "ORD12347",
            customer_id: "CUS001",
            order_date: "2024-09-23",
            order_status: "Pending",
            total_amount: 300.0,
            payment_status: "Success",
            shipping_id: "SHIP125",
            order_notes: "",
        },
        {
            order_id: "ORD12348",
            customer_id: "CUS001",
            order_date: "2024-09-22",
            order_status: "Pending",
            total_amount: 150.5,
            payment_status: "Success",
            shipping_id: "SHIP126",
            order_notes: "Customer cancelled",
        },
    ];

}

export function GetPaymentsById(token) {
    return [
        {
            payment_id: "PAY12345",
            order_id: "ORD12345",
            payment_method: "Credit Card",
            payment_date: "2024-09-25",
            payment_status: "Pending",
            amount_paid: 100.5,
            transaction_id: "TXN98765",
            payment_notes: "Payment completed via Visa card",
        },
        {
            payment_id: "PAY12346",
            order_id: "ORD12346",
            payment_method: "Bank Transfer",
            payment_date: "2024-09-24",
            payment_status: "Paid",
            amount_paid: 200.75,
            transaction_id: "TXN98766",
            payment_notes: "Waiting for confirmation",
        },
        {
            payment_id: "PAY12347",
            order_id: "ORD12347",
            payment_method: "Credit Card",
            payment_date: "2024-09-23",
            payment_status: "Paid",
            amount_paid: 300.0,
            transaction_id: "TXN98767",
            payment_notes: "",
        },
        {
            payment_id: "PAY12348",
            order_id: "ORD12348",
            payment_method: "Credit Card",
            payment_date: "2024-09-22",
            payment_status: "Paid",
            amount_paid: 150.5,
            transaction_id: "TXN98768",
            payment_notes: "Refunded after cancellation",
        },
    ]
}

export function GetHistoryByUserId(token) {
    return [
        {
            history_id: "HIST001",
            order_id: "ORD12345",
            action_date: "2024-09-25",
            action_type: "Status Update",
            action_by: "Admin001",
            notes: "Order status changed to 'Processing'",
        },
        {
            history_id: "HIST002",
            order_id: "ORD12346",
            action_date: "2024-09-24",
            action_type: "Status Update",
            action_by: "Admin002",
            notes: "Order status changed to 'Shipped'",
        },
        {
            history_id: "HIST003",
            order_id: "ORD12347",
            action_date: "2024-09-23",
            action_type: "Delivered",
            action_by: "Admin003",
            notes: "Order delivered successfully",
        },
        {
            history_id: "HIST004",
            order_id: "ORD12348",
            action_date: "2024-09-22",
            action_type: "Cancelled",
            action_by: "Admin004",
            notes: "Order was cancelled by customer",
        },
        {
            history_id: "HIST005",
            order_id: "ORD12349",
            action_date: "2024-09-21",
            action_type: "Status Update",
            action_by: "Admin005",
            notes: "Order status changed to 'Processing'",
        },
        {
            history_id: "HIST006",
            order_id: "ORD12350",
            action_date: "2024-09-20",
            action_type: "Shipped",
            action_by: "Admin006",
            notes: "Order was shipped via express",
        },
        {
            history_id: "HIST007",
            order_id: "ORD12351",
            action_date: "2024-09-19",
            action_type: "Delivered",
            action_by: "Admin007",
            notes: "Order delivered",
        },
        {
            history_id: "HIST008",
            order_id: "ORD12352",
            action_date: "2024-09-18",
            action_type: "Cancelled",
            action_by: "Admin008",
            notes: "Customer requested cancellation",
        },
        {
            history_id: "HIST009",
            order_id: "ORD12353",
            action_date: "2024-09-17",
            action_type: "Status Update",
            action_by: "Admin009",
            notes: "Order status changed to 'Processing'",
        },
        {
            history_id: "HIST010",
            order_id: "ORD12354",
            action_date: "2024-09-16",
            action_type: "Shipped",
            action_by: "Admin010",
            notes: "Order shipped via standard shipping",
        },
    ]
}

export function GetShippingByUserId(token) {
    return [
        {
            shipping_id: "SHIP123",
            order_id: "ORD12345",
            shipping_address: "1234 Main St, City, Country",
            shipping_method: "Express",
            shipping_status: "Shipped",
            shipping_date: "2024-09-26",
            tracking_number: "TRACK98765",
            shipping_notes: "Delivered within 2-3 business days",
        },
        {
            shipping_id: "SHIP124",
            order_id: "ORD12346",
            shipping_address: "5678 Elm St, City, Country",
            shipping_method: "Standard",
            shipping_status: "Pending",
            shipping_date: "2024-09-27",
            tracking_number: "TRACK98766",
            shipping_notes: "Deliver after 6 PM",
        },
        {
            shipping_id: "SHIP125",
            order_id: "ORD12347",
            shipping_address: "4321 Oak St, City, Country",
            shipping_method: "Express",
            shipping_status: "Delivered",
            shipping_date: "2024-09-23",
            tracking_number: "TRACK98767",
            shipping_notes: "",
        },
        {
            shipping_id: "SHIP126",
            order_id: "ORD12348",
            shipping_address: "9876 Pine St, City, Country",
            shipping_method: "Express",
            shipping_status: "Cancelled",
            shipping_date: "",
            tracking_number: "",
            shipping_notes: "Order cancelled",
        },
        {
            shipping_id: "SHIP127",
            order_id: "ORD12349",
            shipping_address: "2468 Maple St, City, Country",
            shipping_method: "Express",
            shipping_status: "Processing",
            shipping_date: "",
            tracking_number: "",
            shipping_notes: "Urgent delivery requested",
        },
        {
            shipping_id: "SHIP128",
            order_id: "ORD12350",
            shipping_address: "1357 Cedar St, City, Country",
            shipping_method: "Standard",
            shipping_status: "Shipped",
            shipping_date: "2024-09-20",
            tracking_number: "TRACK98768",
            shipping_notes: "Send to office address",
        },
        {
            shipping_id: "SHIP129",
            order_id: "ORD12351",
            shipping_address: "2468 Birch St, City, Country",
            shipping_method: "Standard",
            shipping_status: "Delivered",
            shipping_date: "2024-09-19",
            tracking_number: "TRACK98769",
            shipping_notes: "",
        },
        {
            shipping_id: "SHIP130",
            order_id: "ORD12352",
            shipping_address: "1357 Spruce St, City, Country",
            shipping_method: "Express",
            shipping_status: "Cancelled",
            shipping_date: "",
            tracking_number: "",
            shipping_notes: "Wrong item ordered",
        },
        {
            shipping_id: "SHIP131",
            order_id: "ORD12353",
            shipping_address: "8642 Fir St, City, Country",
            shipping_method: "Standard",
            shipping_status: "Processing",
            shipping_date: "",
            tracking_number: "",
            shipping_notes: "",
        },
        {
            shipping_id: "SHIP132",
            order_id: "ORD12354",
            shipping_address: "7531 Palm St, City, Country",
            shipping_method: "Express",
            shipping_status: "Shipped",
            shipping_date: "2024-09-16",
            tracking_number: "TRACK98770",
            shipping_notes: "Gift wrap requested",
        },
    ];
}