import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import React from "react";

function ShippingTableRow(props) {
  const { shipping_id, order_id, shipping_address, shipping_method, shipping_status, shipping_date, tracking_number } = props;
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
       <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {shipping_id}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" align='center'>
            {order_id}
          </Text>
        </Flex>
      </Td>

      <Td borderColor={borderColor}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" align='center'>
            {shipping_address}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {shipping_method}
        </Text>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {shipping_status}
        </Text>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {shipping_date}
        </Text>
      </Td>
      <Td borderColor={borderColor}>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem" align='center'>
          {tracking_number}
        </Text>
      </Td>
    </Tr>
  );
}

export default ShippingTableRow;
