// import
import React from 'react'; 
import Profile from "views/Dashboard/Profile.js";
import History from "views/Dashboard/History.js"; 
import Order from "views/Dashboard/Order.js"; 
import Payment from "views/Dashboard/Payment.js"; 
import Shipping from "views/Dashboard/Shipping.js"; 

import { 
  PersonIcon,
  DocumentIcon, 
} from "components/Icons/Icons";
import { CreditIcon } from 'components/Icons/Icons';

var dashRoutes = [
  {
    path: "/order",
    name: "Order",
    rtlName: "وحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: Order,
    layout: "/user",
  },
  {
    path: "/history",
    name: "History",
    rtlName: "وحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: History,
    layout: "/user",
  },
  {
    path: "/payment",
    name: "Payment",
    rtlName: "وحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: Payment,
    layout: "/user",
  },
  {
    path: "/shipping",
    name: "Shipping",
    rtlName: "وحة القيادة",
    icon: <DocumentIcon color='inherit' />,
    component: Shipping,
    layout: "/user",
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "لوحة القيادة",
    icon: <PersonIcon color='inherit' />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/user",
  },
];

export default dashRoutes;
