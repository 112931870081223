// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import HistoryTableRow from "components/Tables/HistoryTableRow";
import { GetHistoryByUserId } from "lib/service/api";
import { GetProfile } from "lib/service/api";
import React, { useEffect, useState } from "react"; 

function History() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [histories, setHistories] = useState([])

  useEffect(() => {
      handleGetHistory();
  }, [])

  async function handleGetHistory() {
      const resHistories = await GetHistoryByUserId(GetProfile());
      setHistories(resHistories)
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
          History Table
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400" >
              <Th borderColor={borderColor} color="gray.400">History ID</Th>
                <Th borderColor={borderColor} color="gray.400" textAlign="center">Order ID </Th>
                <Th borderColor={borderColor} color="gray.400" textAlign="center">Action Date</Th>
                <Th borderColor={borderColor} color="gray.400" textAlign="center">Action Type</Th>
                <Th borderColor={borderColor} color="gray.400" textAlign="center">Action By</Th>
              </Tr>
            </Thead>
            <Tbody>
              {histories.map((row, index, arr) => {
                return (
                  <HistoryTableRow
                    history_id={row.history_id}
                    order_id={row.order_id}
                    action_date={row.action_date}
                    action_type={row.action_type}
                    action_by={row.action_by}
                    key={index}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default History;
